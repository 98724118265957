<template>
    <div class="py-5 osahan-coming-soon d-flex justify-content-center align-items-center">
        <div class="col-md-6">
            <div class="text-center pb-3">
                <h3 v-if="$store.state.curUser" class="font-weight-bold" ><span class="text-uppercase">{{userName}}</span>, Your order  has been successful</h3>
        <p>Check your order #<span class="font-weight-bolder text-info">{{lastOrderCode}}</span> status in <a href="my_order" class="font-weight-bold text-decoration-none text-primary">My Orders</a> about next steps information. </p>
            </div>
            <!-- continue -->
            <div class="bg-white rounded text-center p-4 shadow-sm">
                <!-- <h1 class="display-1 mb-4">🎉</h1> -->
                <h1 class="display-1 mb-4 icon text-success"><i class="feather-check-circle"></i></h1>
                <h6 class="font-weight-bold mb-2">Preparing your order</h6>
                <p class="small text-muted">Your order will be prepared and will come soon</p>
                <!-- <a href="status_onprocess" class="btn rounded btn-primary btn-lg btn-block">Track My Order</a> -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      userName: JSON.parse(this.$store.state.curUser).user.user_name,
      lastOrder: JSON.parse(window.localStorage.getItem("lastOrder")),
      lastOrderCode:window.localStorage.getItem('lastOrderCode')
    };
  },
  mounted() {
    this.$store.dispatch("getUser");
  },
};
</script>